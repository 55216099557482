// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-js": () => import("/var/www/splashmedia/releases/20190709180001/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-series-js": () => import("/var/www/splashmedia/releases/20190709180001/src/templates/series.js" /* webpackChunkName: "component---src-templates-series-js" */),
  "component---src-templates-tag-js": () => import("/var/www/splashmedia/releases/20190709180001/src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-author-js": () => import("/var/www/splashmedia/releases/20190709180001/src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-pages-404-js": () => import("/var/www/splashmedia/releases/20190709180001/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("/var/www/splashmedia/releases/20190709180001/src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-admin-index-js": () => import("/var/www/splashmedia/releases/20190709180001/src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-support-js": () => import("/var/www/splashmedia/releases/20190709180001/src/pages/admin/support.js" /* webpackChunkName: "component---src-pages-admin-support-js" */),
  "component---src-pages-company-js": () => import("/var/www/splashmedia/releases/20190709180001/src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-feature-js": () => import("/var/www/splashmedia/releases/20190709180001/src/pages/feature.js" /* webpackChunkName: "component---src-pages-feature-js" */),
  "component---src-pages-index-js": () => import("/var/www/splashmedia/releases/20190709180001/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-js": () => import("/var/www/splashmedia/releases/20190709180001/src/pages/inquiry.js" /* webpackChunkName: "component---src-pages-inquiry-js" */),
  "component---src-pages-life-js": () => import("/var/www/splashmedia/releases/20190709180001/src/pages/life.js" /* webpackChunkName: "component---src-pages-life-js" */),
  "component---src-pages-press-js": () => import("/var/www/splashmedia/releases/20190709180001/src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-series-js": () => import("/var/www/splashmedia/releases/20190709180001/src/pages/series.js" /* webpackChunkName: "component---src-pages-series-js" */),
  "component---src-pages-work-js": () => import("/var/www/splashmedia/releases/20190709180001/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/var/www/splashmedia/releases/20190709180001/.cache/data.json")

