module.exports = [{
      plugin: require('/var/www/splashmedia/shared/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"quality":90,"showCaptions":true,"linkImagesToOriginal":false},
    },{
      plugin: require('/var/www/splashmedia/shared/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-121905128-2"},
    },{
      plugin: require('/var/www/splashmedia/shared/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/www/splashmedia/releases/20190709180001/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
